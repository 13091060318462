import { Link } from "@inertiajs/react";
import { memo } from "react";

import BB_ImageWrapper from "@/Components/BB_ImageWrapper";
import BB_Product from "@/types/BB_Product";

export type BB_ProductCellProps = {
	product: BB_Product;
	index: number;
	isCompact: boolean;
	title?: string;
};

const BB_ProductCell = (props: BB_ProductCellProps) => {
	if (props.isCompact) {
		return (
			<Link
				id={"p_" + props.index.toString()}
				key={"p_" + props.product.b_id}
				href={route("p", { b_id: props.product.b_id, slug: props.product.slug })}
				title={props.product.title ?? props.product.name}
				className="bg-background-50 dark:bg-dark-background-400 hover:bg-background-100 dark:hover:bg-dark-background-350 ripple-effect ripple-background-200 dark:ripple-dark-background-500 flex h-[5rem] w-full items-center justify-between overflow-hidden rounded-lg bg-clip-padding shadow-lg">
				<div className="relative h-full w-26">
					<BB_ImageWrapper
						bb_image={props.product.image}
						suffix="?class=3"
						addPadding={props.product.image?.trimmed === 1}
						className="pointer-events-none h-full w-26 max-w-26 bg-white object-contain select-none"
						alt="Produkt"
					/>
					<div className="dark:bg-dark-background-50/10 pointer-events-none absolute inset-0"></div>
				</div>

				<div className="mx-3 flex h-[5rem] flex-1 flex-col justify-center py-2 text-left">
					<h3 className="pointer-events-none line-clamp-2 overflow-hidden text-sm font-semibold hyphens-auto text-gray-900 select-none dark:text-white">
						{props.title || props.product.title || props.product.name}
					</h3>
					<p className="pointer-events-none line-clamp-1 text-xs hyphens-auto text-gray-500 select-none dark:text-gray-400">
						{props.product.category?.title || props.product.category?.name}
					</p>
				</div>
			</Link>
		);
	} else {
		return (
			<Link
				id={"p_" + props.index.toString()}
				key={"p_" + props.product.b_id}
				href={route("p", { b_id: props.product.b_id, slug: props.product.slug })}
				title={props.product.title ?? props.product.name}
				className="bg-background-50 dark:bg-dark-background-400 hover:bg-background-100 dark:hover:bg-dark-background-350 ripple-effect ripple-background-200 dark:ripple-dark-background-500 flex w-full flex-col overflow-hidden rounded-lg bg-clip-padding shadow-lg">
				<div className="item-image relative flex h-32 max-h-32 flex-1 items-center justify-center bg-white">
					<BB_ImageWrapper
						bb_image={props.product.image}
						suffix="?class=3"
						addPadding={props.product.image?.trimmed === 1}
						className="pointer-events-none h-32 max-h-32 max-w-full object-contain select-none"
						alt="Produkt"
					/>
					<div className="dark:bg-dark-background-50/10 pointer-events-none absolute inset-0"></div>
				</div>

				<h3 className="pointer-events-none mt-2 line-clamp-2 overflow-hidden px-3 text-left text-sm leading-5 font-semibold hyphens-auto text-gray-900 select-none dark:text-white">
					{props.title || props.product.title || props.product.name}
				</h3>

				<div className="mt-1 flex w-full items-center justify-between px-3 pb-3">
					<p className="pointer-events-none line-clamp-1 text-xs hyphens-auto text-gray-500 select-none dark:text-gray-400">
						{props.product.category?.title || props.product.category?.name}
					</p>
				</div>
			</Link>
		);
	}
};

export default memo(BB_ProductCell);
